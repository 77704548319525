'use client';
import Image from 'next/image';
import { auth } from '../../lib/firebaseClient';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Error logging in:', error);
      window.location.href = '/';
    }
  };

  return (
    <main className='bg-gray-50'>
      <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0'>
        <a href='#' className='flex items-center mb-6 text-2xl font-semibold text-gray-900'>
          <Image
            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 64'
            width={64}
            height={43}
            style={{ width: 'auto', height: '32px' }}
            loading='lazy'
            className='h-8 w-auto'
            src='/assets/images/Logo-Samsat.webp'
            alt='Logo Samsat'
          />
          Admin Login
        </a>
        <div className='w-full bg-white rounded-lg shadow lg:mt-0 sm:max-w-md xl:p-0'>
          <div className='p-6 space-y-4 lg:space-y-6 sm:p-8'>
            <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 lg:text-2xl'>
              Sign in to your account
            </h1>
            <form onSubmit={handleLogin} className='space-y-4 lg:space-y-6' action='#'>
              <div>
                <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900'>Your email</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type='email' name='email' id='email' className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5' placeholder='username@email.com' required />
              </div>
              <div>
                <label htmlFor='password' className='block mb-2 text-sm font-medium text-gray-900'>Password</label>
                <input value={password} onChange={(e) => setPassword(e.target.value)} type='password' name='password' id='password' placeholder='••••••••' className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5' required />
              </div>
              <button type='submit' className='w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'>Sign in</button>
              <p className='text-sm font-light text-gray-500'>
                You're not authorized to access this page.<br></br><a href='/' className='font-medium text-blue-600 hover:underline'>Take me back to the home page.</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
